.header-container{
    position: sticky;
    display: flex;
    justify-content: center;
    top:0;
    margin-top: 3em;
    width: 100%;
    height: 8em;
    grid-column: 3/11;
   
}

.logo-image{
    width: 100%;
    height: auto;
}

.getaways-header{
    font-family: 'Yantramanav';
    font-size: 3.2em;
    letter-spacing: 0.2em;
}

@media only screen and (max-width: 800px) {
    .header-container{
        position: relative;
    }
    .getaways-header{
        font-size: 2.6em;
    }
}