.App{
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(12, 1fr);
  background: linear-gradient(360deg,  rgba(255,238,223,1) 5%, rgba(225,234,252,1) 55%, rgb(185, 227, 242) 99%);
}

.event-background{
  background: linear-gradient(360deg, rgb(0, 0, 0) 5%,  rgba(255,238,223,1) 5%, rgba(225,234,252,1) 55%, rgb(185, 227, 242) 99%);
}

.page-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.layout{
  /* border: solid 1px red; */
}

.layout2{
  /* border: solid 1px burlywood; */
}

