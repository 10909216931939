.footer-container{
    display:flex;
    height: 3em;
    padding: 3em 0;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}


.discord-logo{
    height: 1.2em;
}

.twitter-logo{
    
}

.footer-image path{
    fill: black;
}

.text-balloon-container{
    position: relative;
    height:  3em;
}

.text-balloon{
    position: absolute;
    top: -5.7em;
    left: -5.9em;
    height: 6em;
}

.text-balloon-text{
    position: absolute;
    top: -6em;
    left: -6.3em;
    height: 6em;
    width: 10em;
    text-align: center;
    font-size: 0.8em;
}

@media only screen and (max-width: 500px) {
    .footer-container{
        display: flex;
        
        padding: 5em 0;
        justify-content: center;
    }
    .text-balloon-container{
        display: none;
    }
    .twitter-logo{
            margin-right: 0.7em;
            padding: 0.05em;
    }
    .discord-logo{
    height: 1em;
}
}