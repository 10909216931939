
.canvas-example-container{
    position: relative;
    z-index: 100;
    width: 16em;
    height: 17em;
    background-color: rgb(220, 238, 247);
    cursor: grab;
}

.canvas-example-container:active{
    cursor: grabbing;
}

.getaways-example-header{
    position: absolute;
    bottom: 0.7em;
    right:0.7em;
    width: 4em;
    z-index: 1000;

 
}

@media only screen and (max-width: 950px) {
    .canvas-example-container{
        width: 12em;
        height: 13em;
    }
}


@media only screen and (max-width: 800px) {
    .canvas-example-container{
        width: 16em;
        height: 17em;
    }
}


@media only screen and (max-width: 500px) {
    .canvas-example-container{
        width: 13em;
        height: 14em;
    }
}