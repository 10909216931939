* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  
}

p{
  font-family: 'Raleway';
  font-size: 0.95em;
  text-align: center;
}

h1,h2,h3{
  font-family: 'Yantramanav';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@import url('https://fonts.googleapis.com/css2?family=Yantramanav');
@import url('https://fonts.googleapis.com/css2?family=Raleway');