@import url(https://fonts.googleapis.com/css2?family=Yantramanav);
@import url(https://fonts.googleapis.com/css2?family=Raleway);
* {
  box-sizing: border-box;
}


body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  
}

p{
  font-family: 'Raleway';
  font-size: 0.95em;
  text-align: center;
}

h1,h2,h3{
  font-family: 'Yantramanav';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App{
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  grid-template-columns: repeat(12, 1fr);
  background: linear-gradient(360deg,  rgba(255,238,223,1) 5%, rgba(225,234,252,1) 55%, rgb(185, 227, 242) 99%);
}

.event-background{
  background: linear-gradient(360deg, rgb(0, 0, 0) 5%,  rgba(255,238,223,1) 5%, rgba(225,234,252,1) 55%, rgb(185, 227, 242) 99%);
}

.page-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.layout{
  /* border: solid 1px red; */
}

.layout2{
  /* border: solid 1px burlywood; */
}


.canvas-carousel-container{
 
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%
    
}


.intro-section{
    width: 100%;
    height: auto;
    text-align: center;
}

.canvas-container{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50em;
    margin-bottom: 15em;
  
}

.canvas-container{
    cursor: grab;
}
.canvas-container:active{
    cursor: grabbing;
}

.poem-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4em 8em;
}

.canvas-event{
    position: fixed;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
}


.examples-grid-container,.examples-grid-container-beetles{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    justify-content: space-evenly;
    grid-gap: 2em;
    gap: 2em;
    width: 100%;
    height: auto;
    z-index: 0;
   
}

.examples-grid-container{
    padding-top: 69em;
}

.examples-grid-container-beetles{
    padding: 7em 0 10em 0;
}



.info-page-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 60em;
    margin-top: 20em;
}


.parallax-outer, .parallax-inner{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
 

.event-quote{
    position: fixed;
    z-index: 200;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}


.islandImage{
    width: 100%;
}

.quote-container{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    width: 100%;
}

.chevron{
    font-size: 2em !important;
    position: absolute;
    z-index: 1000;
    
}

.left{
    left: 17%;
}

.right{
    right: 17%;
}

.button{
 
    height: 3em;
    width: 8em;
    background-color: black;
    border-style: none;
    color: whitesmoke
}

.button:hover{
    cursor: pointer;
   background-color: whitesmoke;
   color: black;
}

.button:active{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.eyes-blocked:hover{
    color: red;
    cursor: pointer;
}

.eyes-active:hover{
    color: whitesmoke;
    cursor: pointer;
}

.header-icons-container{
    display:flex;
    position: absolute;
    top: 3.2em;
    right: 5em;
    height: 3em;
    padding: 3em 0;
    align-items: center;
    justify-content: flex-end;
}

.nav-link{
    font-size: 0.9em;
    letter-spacing: 0.2em; 
}

.nav-link:hover{
    cursor: pointer;
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    color: whitesmoke;
}

.header-icons-wrapper{
    display: flex;
    justify-content: space-between;
    width: 100%
}

.faq-container{
    text-align: left;
    padding: 7em 3em 1em 3em;
    margin-top: 7em;
    margin-bottom: 12em;   
    background: linear-gradient(360deg,  rgba(255,238,223,1) 5%, rgba(225,234,252,1) 65%, rgb(185, 227, 242) 110%);
    z-index: 100;
    box-shadow: 0 50px 50px rgb(0 0 0 / 3%);
}

.faq-p{
    padding: 3em 0em;
    text-align: left;
}

.example-beetle-img{
    width: 50%;
}


@media only screen and (max-width: 1200px) {
    .left{
        left: 7%;
    }
    
    .right{
        right: 7%;
    }
    .header-icons-container{
      
        display:flex;
        position: absolute;
        top: 2em;
        right: 1em;
    
        -webkit-transform: translate(0,-50%);
    
                transform: translate(0,-50%);
        justify-content: flex-end;
        width: 100%;
        height: 3em;
        padding: 3em 0;
    }
}

@media only screen and (max-width: 900px){
    .canvas-container{
        margin-top: 3.5em;
        width: 120%;
        height: 50em;
        margin-bottom: 10em;
      
    }
    .left{
        left: 5%;
    }
    
    .right{
        right: 5%;
    }
    .faq-container{
        width: 85vw;
        margin-bottom: 8em;
        margin-top: 4em;
    }
    .cardano-logo{
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .examples-grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 15em;
    }

    .examples-grid-container-beetles{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10em;
    }

    .canvas-container{
        width: 100%;
        height: 40em;
    }
}

@media only screen and (max-width: 500px) {
    .examples-grid-container{
        padding-top: 17em;
    }
    .canvas-container{
        width: 100%;
        height: 15em;
    }
    .poem-container{
        padding: 2em 2em;
    }
    .left{
        left: 30%;
        bottom: -1em;
    }
    
    .right{
        right: 30%;
        bottom: -1em;
    }
    .faq-container{
        margin-top: 3em;
    }


.policy-ID{
    font-size: 0.3em !important;
}



}

@media only screen and (max-width: 350px){
    .header-icons-container{
        left: 3%;
    }
}

.canvas-example-container{
    position: relative;
    z-index: 100;
    width: 16em;
    height: 17em;
    background-color: rgb(220, 238, 247);
    cursor: grab;
}

.canvas-example-container:active{
    cursor: grabbing;
}

.getaways-example-header{
    position: absolute;
    bottom: 0.7em;
    right:0.7em;
    width: 4em;
    z-index: 1000;

 
}

@media only screen and (max-width: 950px) {
    .canvas-example-container{
        width: 12em;
        height: 13em;
    }
}


@media only screen and (max-width: 800px) {
    .canvas-example-container{
        width: 16em;
        height: 17em;
    }
}


@media only screen and (max-width: 500px) {
    .canvas-example-container{
        width: 13em;
        height: 14em;
    }
}
.header-container{
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    justify-content: center;
    top:0;
    margin-top: 3em;
    width: 100%;
    height: 8em;
    grid-column: 3/11;
   
}

.logo-image{
    width: 100%;
    height: auto;
}

.getaways-header{
    font-family: 'Yantramanav';
    font-size: 3.2em;
    letter-spacing: 0.2em;
}

@media only screen and (max-width: 800px) {
    .header-container{
        position: relative;
    }
    .getaways-header{
        font-size: 2.6em;
    }
}
.footer-container{
    display:flex;
    height: 3em;
    padding: 3em 0;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}


.discord-logo{
    height: 1.2em;
}

.twitter-logo{
    
}

.footer-image path{
    fill: black;
}

.text-balloon-container{
    position: relative;
    height:  3em;
}

.text-balloon{
    position: absolute;
    top: -5.7em;
    left: -5.9em;
    height: 6em;
}

.text-balloon-text{
    position: absolute;
    top: -6em;
    left: -6.3em;
    height: 6em;
    width: 10em;
    text-align: center;
    font-size: 0.8em;
}

@media only screen and (max-width: 500px) {
    .footer-container{
        display: flex;
        
        padding: 5em 0;
        justify-content: center;
    }
    .text-balloon-container{
        display: none;
    }
    .twitter-logo{
            margin-right: 0.7em;
            padding: 0.05em;
    }
    .discord-logo{
    height: 1em;
}
}
